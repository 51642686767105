import React, { FC } from 'react'
import styled from "styled-components";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
`

type NewLogoPros = {
  imgStyle?: React.CSSProperties
}

const NewLogo: FC<NewLogoPros> = ({imgStyle}) => {
  return (
    <Content>
      <img style={imgStyle ?? {}} src="/imgs/newLogo.svg" alt="" />
      <img style={{ display: 'none' }} src="/imgs/pdfLogo.png" alt="" />
    </Content>
  )
}

export default NewLogo
