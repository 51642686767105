import ActiveModel from "./ActiveModel";
import SubclassModel from "./SubclassModel";
import { Details } from "../pages/main/query/products/product-detail";

export type ProductModel = {
    idProduct?: number,
    idBody: number,
    idTypeUse: number,
    description: string,
    active: ActiveModel[],
    subclass?: SubclassModel[],
    productName: string,
    mechanismAction: string,
    pH: string,
    minimumDosage: string,
    maxDosage: string,
    source: string,
    incompatibility: string,
    pregnant: boolean,
    vegan: boolean,
    organic: boolean,
    period: string,
    daytime?: number|null,
    nighttime?: number|null,
    percentage?: string,
    favorite?: number,
    classes: string|null,
    annotation?: string,
    showConcentration?: boolean,
}

export const productModelToDetails = (product: ProductModel) => {
    return {
        name: product.productName,
        mechanism: product.mechanismAction,
        description: product.description,
        ph: product.pH,
        incompatibility: product.incompatibility,
        minimum: product.minimumDosage,
        maximum: product.maxDosage,
        source: product.source,
        daytime: product.daytime,
        nighttime: product.nighttime,
        vegan: product.vegan,
        organic: product.organic,
        pregnant: product.pregnant,
        classes: product.classes,
        id: product.idProduct,
        favorite: !!product.favorite,
        annotation: product.annotation,
        showConcentration: product.showConcentration,
    } as Details
}
